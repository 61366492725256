import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const keysToIgnore = [
  "source",
  "sourceHandle",
  "target",
  "targetHandle",
  "labelStyle",
  "labelBgStyle",
  "style",
  "markerEnd",
  "is_new",
  "animated",
  "timestamp",
  "id",
  "selected",
  "is_modified",
  "oid",
  "operationType",
  "changeType",
];

const compareValues = (key, value1, value2) => {
  if (typeof value1 === "object" && typeof value2 === "object") {
    return JSON.stringify(value1) !== JSON.stringify(value2);
  }
  return value1 !== value2;
};

const TransitionChanges = ({ transition, originalTransition }) => {
  const { t } = useTranslation("tracesHistory");
  return Object.keys(transition).map((key) => {
    if (keysToIgnore.includes(key)) return null;

    if (["type", "label", "targetName", "sourceName"].includes(key)) {
      return (
        <div key={key} style={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ marginLeft: 2, fontSize: "14px", color: "white" }}>
            {t(key)}:
          </Typography>
          <Typography sx={{ marginLeft: 2, fontSize: "14px", color: "white" }}>
            {transition[key].toString()}
          </Typography>
        </div>
      );
    }

    if (key === "conditions" && transition[key].length > 0) {
      return (
        <div key={key} style={{ marginLeft: 2 }}>
          <Typography sx={{ fontSize: "14px" }}>{t(key)}:</Typography>
          {transition[key].map((condition, index) => (
            <Box key={index} sx={{ marginLeft: 2, marginTop: 1 }}>
              <Typography sx={{ fontSize: "14px" }}>
                {`Condition ${index + 1}:`}
              </Typography>
              <Typography
                sx={{
                  marginLeft: 2,
                  fontSize: "14px",
                  color: "tracesName.new",
                }}>
                {`Operation: ${condition.op}`}
              </Typography>
              {typeof condition.value === "object" ? (
                <>
                  {Object.entries(condition.value).map(([subKey, subValue]) => (
                    <Typography
                      key={subKey}
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "tracesName.new",
                      }}>
                      {`${t(
                        subKey.charAt(0).toUpperCase() + subKey.slice(1),
                      )}: ${subValue.toString()}`}
                    </Typography>
                  ))}
                </>
              ) : (
                <Typography
                  sx={{
                    marginLeft: 2,
                    fontSize: "14px",
                    color: "tracesName.new",
                  }}>
                  {`Value: ${condition.value}`}
                </Typography>
              )}
            </Box>
          ))}
        </div>
      );
    }

    if (key === "entity" || key === "media") {
      return (
        <div key={key} style={{ marginLeft: 2 }}>
          <Typography sx={{ fontSize: "14px" }}>{t(key)}:</Typography>
          {Object.entries(transition[key]).map(([subKey, subValue]) => (
            <Typography
              key={subKey}
              sx={{
                marginLeft: 2,
                fontSize: "14px",
                color: "tracesName.new",
              }}>
              {`${t(
                subKey.charAt(0).toUpperCase() + subKey.slice(1),
              )}: ${subValue.toString()}`}
            </Typography>
          ))}
        </div>
      );
    }

    return (
      <div key={key} style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {t(key)}:
        </Typography>
        {compareValues(key, transition[key], originalTransition[key]) ? (
          <>
            <Typography
              sx={{
                marginLeft: 2,
                fontSize: "14px",
                color: "tracesName.delete",
                textDecoration: "line-through",
              }}>
              {originalTransition[key] !== undefined
                ? originalTransition[key].toString()
                : "N/A"}
            </Typography>
            <Typography
              sx={{
                marginLeft: 2,
                fontSize: "14px",
                color: "tracesName.new",
              }}>
              {transition[key].toString()}
            </Typography>
          </>
        ) : (
          <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
            {transition[key].toString()}
          </Typography>
        )}
      </div>
    );
  });
};

export default TransitionChanges;
