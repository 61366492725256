import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import ResponseChanges from "./ResponseChanges.component";
import QuickRepliesChanges from "./QuickRepliesChanges.component";
import PositionChanges from "./PositionChanges.component";
import TransitionChanges from "./TransitionChanges.component";
import AiChanges from "./AiChanges.component";

const ExpandMore = styled((props) => {
  const { id, ...other } = props;
  return <IconButton id={id} {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const colors = {
  create: "tracesType.create",
  update: "tracesType.update",
  delete: "tracesType.delete",
};

const CardTrace = ({ trace }) => {
  const [expanded, setExpanded] = useState(false);
  const [date, setDate] = useState("");
  const [color] = useState(colors[trace.type]);
  const { t } = useTranslation();

  const types = {
    create: t("tracesHistory:createFlow"),
    update: t("tracesHistory:updateFlow"),
    delete: t("tracesHistory:deleteFlow"),
    edge: t("tracesHistory:edgeFlow"),
    page: t("tracesHistory:pageFlow"),
  };

  const keysToIgnore = [
    "width",
    "height",
    "id",
    "type",
    "is_modified",
    "selected",
    "dragging",
    "operationType",
    "changeType",
    "positionAbsolute",
    "oid",
    "page_type",
    "node_type",
    "is_new",
  ];

  useEffect(() => {
    let date = new Date(trace.date);
    date = date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    setDate(date);
  }, [trace]);

  const normalizeName = (name) => {
    if (name.includes("-")) {
      name = name.replace(/-/g, " ");
    }
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  };

  const renderTitleContent = (trace, types) => {
    return (
      <React.Fragment>
        <Typography>
          {types[trace.type] + types[trace.collectionName]}
        </Typography>
        <Typography sx={{ color: "tracesName.new", marginLeft: 0.5 }}>
          {normalizeName(trace.changes.label)}
        </Typography>
      </React.Fragment>
    );
  };

  function renderTitle(trace, types) {
    let titleContent;

    if (
      trace.type === "update" ||
      (trace.changes.label && trace.type === "create")
    ) {
      titleContent = renderTitleContent(trace, types);
    } else if (trace.changes.id && trace.type !== "create") {
      titleContent = (
        <Typography>
          {types[trace.type] +
            types[trace.collectionName] +
            normalizeName(
              trace.changes.data?.label
                ? trace.changes.data.label
                : trace.changes.label,
            )}
        </Typography>
      );
    } else {
      titleContent = null;
    }

    return (
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        {titleContent}
      </div>
    );
  }

  const compareValues = (key, value1, value2) => {
    if (key === "transfer_agent" || key === "transfer_agent_pages") {
      const defaultValue = [{ name: "", display_name: "None" }];
      if (
        (value1 === undefined &&
          JSON.stringify(value2) === JSON.stringify(defaultValue)) ||
        (value2 === undefined &&
          JSON.stringify(value1) === JSON.stringify(defaultValue))
      ) {
        return false;
      }
    }
    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  function renderChanges(trace) {
    const changes = trace.changes;
    const originalData = trace.originalData || {};

    const changedKeys = Object.keys(changes).filter((key) => {
      const originalKey = getOriginalKey(key);
      return (
        !keysToIgnore.includes(key) &&
        compareValues(key, changes[key], originalData[originalKey])
      );
    });

    return changedKeys.map((key) => {
      const originalKey = getOriginalKey(key);

      if (key === "response") {
        return (
          <ResponseChanges
            key={key}
            response={changes[key]}
            originalResponse={originalData[originalKey]}
          />
        );
      }

      if (key === "quickReplies") {
        return (
          <div key={key}>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {key}:
            </Typography>
            <QuickRepliesChanges
              quickReplies={changes[key]}
              originalQuickReplies={originalData[originalKey]}
            />
          </div>
        );
      }

      if (key === "position") {
        return (
          <div key={key}>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {key}:
            </Typography>
            <PositionChanges
              position={changes[key]}
              originalPosition={originalData[originalKey]}
            />
          </div>
        );
      }

      if (key === "ai") {
        return (
          <div key={key}>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {key}:
            </Typography>
            <div style={{ marginLeft: 4 }}>
              <AiChanges
                ai={changes[key]}
                originalAi={originalData[originalKey]}
              />
            </div>
          </div>
        );
      }

      if (!compareValues(key, changes[key], originalData[originalKey])) {
        return null;
      }

      return (
        <div key={key}>
          <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
            {key}:
          </Typography>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.delete",
              textDecoration: "line-through",
            }}>
            {formatOriginalData(originalData[originalKey])}
          </Typography>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.new",
            }}>
            {formatChangesData(changes[key])}
          </Typography>
        </div>
      );
    });
  }

  const getOriginalKey = (key) => {
    if (key === "label") return "name";
    if (key === "ignoreNoMatch") return "ignoreAllnoMatch";
    return key;
  };

  const formatOriginalData = (data) => {
    if (data === undefined) return "N/A";
    return typeof data === "object" ? JSON.stringify(data) : data.toString();
  };

  const formatChangesData = (data) => {
    return typeof data === "object" ? JSON.stringify(data) : data.toString();
  };

  return (
    <Card
      sx={{
        width: "95%",
        marginTop: 1,
        marginLeft: "2.5%",
        boxShadow: "4",
        borderRadius: "5px",
      }}>
      <CardHeader
        avatar={
          <Box
            sx={{
              borderColor: "black",
              borderStyle: "solid",
              borderWidth: 1,
              width: 20,
              height: 20,
              bgcolor: color,
              borderRadius: "50%",
            }}
          />
        }
        title={renderTitle(trace, types)}
        subheader={
          <>
            {date}
            <span style={{ marginLeft: "55px" }}></span>
            {trace.userName}
          </>
        }
        action={
          <div>
            {trace.type !== "delete" &&
            !(
              Object.keys(trace.changes)?.length === 2 && trace.changes.name
            ) ? (
                <ExpandMore
                  id="expand-more-button"
                  expand={expanded}
                  onClick={() => setExpanded(!expanded)}
                  aria-label="show more">
                  <ExpandMoreIcon />
                </ExpandMore>
              ) : null}
          </div>
        }></CardHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {trace.type !== "delete" ? (
            <div>
              <Typography paragraph>
                {t("tracesHistory:subtitlechanges")}
              </Typography>
              {trace.collectionName === "edge" || trace.changes.source ? (
                <TransitionChanges
                  transition={trace.changes}
                  originalTransition={trace.originalData}
                />
              ) : (
                renderChanges(trace)
              )}
            </div>
          ) : null}
        </CardContent>
      </Collapse>
    </Card>
  );
};

CardTrace.propTypes = {
  trace: PropTypes.shape({
    type: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    collectionName: PropTypes.string.isRequired,
    changes: PropTypes.object.isRequired,
    originalData: PropTypes.object,
  }).isRequired,
};

export default CardTrace;
